import { environment } from "@environments/environment";
import { IPortals } from "@interfaces/IPortals";

export const portals: IPortals = {
  licensure: {
    id: "licensure",
    title: "Educator Preparation, Licensure, and Misconduct",
    showDetails: true,
    details: {
      description: "",
      items: [
        {
          isUrl: true,
          value: `${environment.licensureUrl}/Public/Search`,
          urlText: "Public Educator License Search"
        }
      ]
    },
    logins: [
      {
        heading: "Are you an educator looking to manage your licensure?",
        subheading: "Login with your Facebook, Google, Amazon, or your personal email.",
        loginButtonText: "My Mecca",
        loginUrl: `${environment.licensureUrl}/Account/Login/SignIn?policy=B2C_1A_EDUCATOR_SIGNUP_SIGNIN&returnUrl=${encodeURI(environment.baseUrl)}`,
        showRegistrationButton: true,
        registrationUrl: `${environment.licensureUrl}/Account/Login/SignIn?policy=B2C_1A_EDUCATOR_SIGNUP_SIGNIN&returnUrl=${encodeURI(environment.baseUrl)}`,
        registrationText: "Create a MECCA profile"
      },
      {
        heading: "Are you looking to act on behalf of a district or school regarding licensure?",
        subheading: "Login using your employer provided email credentials.",
        loginButtonText: "Admin Access",
        loginUrl: `${environment.licensureUrl}/Account/Login/SignIn?policy=B2C_1A_ADMINISTRATOR_SIGNUP_SIGNIN&returnUrl=${encodeURI(environment.baseUrl)}`,
        showRegistrationButton: false,
        registrationUrl: "",
        registrationText: "Create a MECCA profile"
      },
      {
        heading: "Are you completing license application information on behalf of an EPP?",
        subheading: "Login or register with your institution email address.",
        loginButtonText: "Email Login",
        loginUrl: `${environment.licensureUrl}/Account/Login/SignIn?policy=B2C_1A_EPP_SIGNUP_SIGNIN&returnUrl=${encodeURI(environment.baseUrl)}`,
        showRegistrationButton: true,
        registrationUrl: `${environment.licensureUrl}/Account/Login/SignIn?policy=B2C_1A_EPP_SIGNUP_SIGNIN&option=signup&returnUrl=${encodeURI(environment.baseUrl)}`,
        registrationText: "Create a MECCA profile"
      }
    ]
  },
  observations: {
    id: "observations",
    title: "Professional Growth System Observation and Development",
    showDetails: true,
    details: {
      description: "View or perform observations",
      items: [
        {
          isUrl: false,
          value: "See your observations"
        },
        {
          isUrl: false,
          value: "Perform observations"
        }
      ]
    },
    logins: [
      {
        heading: false,
        subheading: false,
        loginButtonText: "Login",
        loginUrl: `${environment.observationUrl}/secure/dashboard?policy=B2C_1A_ADMINISTRATOR_SIGNUP_SIGNIN&returnUrl=${encodeURI(environment.baseUrl)}`,
        showRegistrationButton: false,
        registrationUrl: `${environment.observationUrl}/secure/dashboard?policy=B2C_1A_ADMINISTRATOR_SIGNUP_SIGNIN&returnUrl=${encodeURI(environment.baseUrl)}`,
        registrationText: "Create a professional profile"
      },
    ]
  },
  jobs: {
    id: "jobs",
    title: "Educator Talent",
    showDetails: true,
    details: {
      description: "",
      items: []
    },
    logins: [
      {
        heading: "Looking for a new position?",
        subheading: "Search open positions across the entire state of Mississippi.",
        loginButtonText: "Browse Positions",
        loginUrl: `${environment.jobsUrl}/secure/home`,
        showRegistrationButton: false,
        registrationUrl: "",
        registrationText: ""
      },
      {
        heading: "Need to post a new opportunity?",
        subheading: "Sign in or register with your organizational email address.",
        loginButtonText: "Get Started",
        loginUrl: `${environment.jobsUrl}/secure/home`,
        showRegistrationButton: false,
        registrationUrl: "",
        registrationText: ""
      },
    ]
  },
  accreditation: {
    id: "accreditation",
    title: "Accreditation",
    showDetails: true,
    details: {
      description: "Get accreditated",
      items: [
        {
          isUrl: false,
          value: "Bullet Point"
        },
        {
          isUrl: false,
          value: "Bullet Point"
        }
      ]
    },
    logins: [
      {
        heading: false,
        subheading: false,
        loginButtonText: "Login",
        loginUrl: `${environment.accreditationUrl}/secure/dashboard?policy=B2C_1A_ADMINISTRATOR_SIGNUP_SIGNIN&returnUrl=${encodeURI(environment.baseUrl)}`,
        showRegistrationButton: false,
        registrationUrl: "",
        registrationText: "Create an accreditation profile"
      },
    ]
  },
}
