// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "@interfaces/IEnvironment";

export const environment: IEnvironment = {
  production: false,
  name: "qa",
  baseUrl: window.location.origin,
  showLicensure: true,
  licensureUrl: "https://meccalic-qa.randasolutions.com",
  showObservation: true,
  observationUrl: "https://meccapgs-qa.randasolutions.com",
  showAccreditation: true,
  accreditationUrl: "https://meccadsa-qa.randasolutions.com",
  showEnvironmentName: true,
  showJobs: true,
  jobsUrl: "https://meccattp-qa.randasolutions.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
